import { globalHistory } from "@reach/router";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Background, Parallax } from "react-parallax";
import { createMarkup } from "../../helpers/createMarkup";
import arrowRight from "../../images/arrow-right.svg";
import Layout from "../Layout";
import SEO from "../Seo";

const PortfolioPageComponent = (props) => {
  const [lang, setLange] = useState("");

  useEffect(() => {
    setLange(globalHistory.location.pathname.split("/")[1]);
  }, []);

  const {
    header_image_title,
    description_project,
    interactive_portfolio,
    gallery_header,
    interactive_portfolio_tech_description,
    gallery_body,
    gallery_footer,
    gallery_next_page,
  } = props.data;
  const cutDescription = description_project?.description.slice(653, 683);
  const cutEndDescription = description_project?.description.slice(0, 653);

  return (
    <Layout>
      <SEO title="Portfolio" />
      <div className="page-portfolio page-default">
        {header_image_title && (
          <>
            <div className="container">
              <div className="heading-panel text-center">
                <div className="heading-sub-title">
                  {header_image_title.sub_title}
                </div>
                <h1 className="pageTitle">{header_image_title.title}</h1>
              </div>
            </div>

            <Parallax
              bgImageAlt=""
              strength={300}
              bgImageSize="cover"
              className="main-picture"
              blur={0}
            >
              <Background className="background_wrapper">
                <img
                  src={header_image_title.preview_image.desktop.replace(
                    /new./,
                    "dashboard."
                  )}
                  alt=""
                />
              </Background>
            </Parallax>
          </>
        )}
        {/* description_project */}
        <div className="container">
          <div className="about-project-information">
            <div className="about-project__title">
              {description_project.title}
            </div>
            <div className="about-project__description">
              <div className="about-project__text">
                <p dangerouslySetInnerHTML={createMarkup(cutEndDescription)} />
              </div>
              {description_project.url_link && description_project.url_title && (
                <div className="about-project__link">
                  {<span className="mr-2">{cutDescription}</span>}

                  <a href={description_project.url_link} target="__blank">
                    {description_project.url_title}
                  </a>
                </div>
              )}
              {description_project.url2_link && description_project.url2_title && (
                <div className="about-project__link">
                  <a href={description_project.url2_link} target="__blank">
                    {description_project.url2_title}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* interactive_portfolio */}
        <div className="about-project-img">
          <div className="container">
            {interactive_portfolio.type === "video" ? (
              <div className="iframe-container">
                <iframe
                  src={interactive_portfolio.url}
                  allowFullScreen=""
                  frameBorder="0"
                />
              </div>
            ) : (
              <div
                className="picture"
                style={{
                  backgroundImage: `url(${interactive_portfolio.image.desktop.replace(
                    /new./,
                    "dashboard."
                  )})`,
                }}
              />
            )}
          </div>
        </div>

        {/* interactive_portfolio_tech_description */}
        <div className="container">
          <div className="about-project-tools">
            {interactive_portfolio_tech_description.map((item, id) => {
              return (
                <div key={id} className="about-project-tools__item">
                  <div className="about-project-tools__title">{item.title}</div>
                  <div
                    className="about-project-tools__description"
                    dangerouslySetInnerHTML={createMarkup(item.text)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* gallery_header */}
        {gallery_header && (
          <div className="preview-picture-section">
            <div className="preview-picture-item">
              <div className="preview-picture__title">
                <div className="container">{gallery_header.description}</div>
              </div>
              <div
                className="preview-picture preview-picture--full"
                style={{
                  backgroundImage: `url(${gallery_header.gallery_image.desktop.replace(
                    /new./,
                    "dashboard."
                  )})`,
                }}
              />
            </div>
          </div>
        )}

        {/* gallery_body */}
        {gallery_body.length > 0 && (
          <div className="preview-picture-section">
            <div className="container">
              <div className="preview-picture-row">
                {gallery_body.map((i, index) => (
                  <div key={index} className="preview-picture-item">
                    <div className="preview-picture__title">
                      {i.description}
                    </div>
                    <div
                      className="preview-picture preview-picture--radius"
                      style={{
                        backgroundImage: `url(${i.gallery_image.desktop.replace(
                          /new./,
                          "dashboard."
                        )})`,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* gallery_footer */}
        {gallery_footer && (
          <>
            <div
              className="react-parallax main-picture"
              style={{ backgroundSize: "100%", width: "100%" }}
            >
              <div className="preview-picture__title">
                <div className="container">
                  Edit Governance Record - contain many WYSIWYG editors,
                  searchable fields etc
                </div>
              </div>
              <Parallax
                bgImageAlt=""
                strength={300}
                bgImageSize="cover"
                className="main-picture react-parallax-background-children"
                blur={0}
              >
                <Background
                  className=" react-parallax-background  background_wrapper"
                  style={{ backgroundSize: "100%" }}
                >
                  <img
                    style={{ backgroundSize: "100%", width: "100%" }}
                    src={gallery_footer.gallery_image.desktop.replace(
                      /new./,
                      "dashboard."
                    )}
                    alt=""
                  />
                </Background>
              </Parallax>
            </div>

            <div className="preview-picture-section">
              <div className="preview-picture-item">
                <div className="preview-picture__title">
                  <div className="container">{gallery_footer.description}</div>
                </div>
                <div className="preview-picture--shell">
                  <div className="react-parallax main-picture">
                    <Parallax
                      bgImageAlt=""
                      strength={300}
                      bgImageSize="cover"
                      className="main-picture last-pic-portfolio"
                      blur={0}
                    >
                      <Background className="background_wrapper">
                        <img
                          src={gallery_footer.gallery_image.desktop.replace(
                            /new./,
                            "dashboard."
                          )}
                          alt=""
                        />
                      </Background>
                    </Parallax>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* gallery_next_page */}
        {gallery_next_page && (
          <div className="next-project-section">
            <div className="container">
              <div className="next-project">
                <div className="next-project-label">
                  {gallery_next_page.sub_title}
                </div>
                <div className="next-project__title">
                  {gallery_next_page.title}
                </div>
                <Link to={`/${lang}/${gallery_next_page.url}`}>
                  <div className="next-project__icon">
                    <img src={arrowRight} alt="arrowRight" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PortfolioPageComponent;
